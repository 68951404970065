<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{ ...$attrs }"
    :class="[`field-${field}`]"
    name="address-floor-level"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import AddressFloorLevelField from 'chimera/all/components/form/fields/addressFloorLevel/AddressFloorLevelField'

export const field = 'future-address-floor-level'
export default {
  name: 'FutureAddressFloorLevelField',

  extends: AddressFloorLevelField,

  props: {
    field: {
      type: String,
      default: field,
    },
  },
}
</script>
