<template>
  <div :class="[`field-${field}`]">
    <TSelectableGroup
      v-model="value"
      :choices="choices"
      :error-messages="errors"
    />
    <FormErrorMessages class="my-3" :error-messages="errors" />
  </div>
</template>

<script>
import ValidationError from 'chimera/all/functions/ValidationError'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import TSelectableGroup from 'chimera/all/components/elements/TSelectableGroup'
import AbstractTSelectableGroupFormField from 'chimera/all/components/form/fields/AbstractTSelectableGroupFormField'

export const field = 'address-property-type'
export default {
  name: 'AddressPropertyTypeRadioField',

  components: {
    TSelectableGroup,
    FormErrorMessages,
  },

  extends: AbstractTSelectableGroupFormField,

  props: {
    field: {
      type: String,
      default: field,
    },
  },

  methods: {
    /**
     * @returns {*[]}
     */
    customErrorMap() {
      return [
        {
          id: ValidationError.reasonIsRequired,
          message: this.$i18n.t(
            'field.address-property-type.validation.required',
          ),
        },
        {
          id: ValidationError.reasonInvalidRegex,
          message: this.$i18n.t(
            'field.address-property-type.validation.invalid',
          ),
        },
        {
          id: ValidationError.reasonInvalidLength,
          message: this.$i18n.t(
            'field.address-property-type.validation.invalid',
          ),
        },
        {
          id: ValidationError.reasonInvalidValue,
          message: this.$i18n.t(
            'field.address-property-type.validation.invalid',
          ),
        },
      ]
    },
  },
}
</script>
