<template>
  <FormModal title="Déménager à" show-back-btn>
    <template #body>
      <MovingToFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import MovingToFormStep from '~/components/form/steps/movingTo/MovingToFormStep'

export default {
  components: {
    MovingToFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Déménager à',
      headDescription: 'Indiquez où vous allez vivre',
      path: '/quotes-requests/demenager-a',
      progressValue: 30,
      checkoutStep: 3,
    }
  },
}
</script>
