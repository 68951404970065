<template>
  <section>
    <div class="form-row space-x-4">
      <div class="w-2/3">
        <FutureAddressPostalRadioField
          :label="$t('field.postal.label')"
          placeholder=""
          @result="onResult"
          v-on="$listeners"
        />
      </div>

      <div class="min-w-min whitespace-nowrap">
        <FutureAddressNumberField
          :label="$t('field.address-number.label')"
          placeholder=""
          @result="onResult"
          v-on="$listeners"
        />
      </div>
    </div>

    <div class="form-row">
      <FutureAddressStreetField
        ref="addressStreet"
        :label="$t('field.address-street.label')"
        placeholder=""
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <FutureAddressCityField
        ref="addressCity"
        :label="$t('field.address-city.label')"
        placeholder=""
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>

    <div v-if="serviceIsInternational" class="form-row">
      <FutureAddressCountryNameField
        ref="country"
        :label="$t('field.address-country-name.label')"
        placeholder=""
        @result="onResult"
        v-on="$listeners"
      />
    </div>
  </section>
</template>

<script>
import FutureAddressPostalRadioField from 'chimera/all/components/form/fields/futureAddressPostal/FutureAddressPostalRadioField'
import FutureAddressNumberField from 'chimera/all/components/form/fields/futureAddressNumber/FutureAddressNumberField'
import FutureAddressStreetField from 'chimera/all/components/form/fields/futureAddressStreet/FutureAddressStreetField'
import FutureAddressCityField from 'chimera/all/components/form/fields/futureAddressCity/FutureAddressCityField'
import FutureAddressFormGroup from 'chimera/all/themes/blueflow/components/form/part/futureAddress/FutureAddressFormGroup'
import FutureAddressCountryNameField from 'chimera/all/components/form/fields/futureAddressCountryName/FutureAddressCountryNameField'
import usesMovingServices from 'chimera/moving/mixins/usesMovingServices'

export default {
  name: 'FutureAddressFormGroup',

  components: {
    FutureAddressCountryNameField,
    FutureAddressPostalRadioField,
    FutureAddressNumberField,
    FutureAddressStreetField,
    FutureAddressCityField,
  },

  extends: FutureAddressFormGroup,

  mixins: [usesMovingServices],
}
</script>
