<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{ ...$attrs }"
    :class="[`field-${field}`]"
    name="address-street"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import AddressStreetField from 'chimera/all/components/form/fields/addressStreet/AddressStreetField'

export const field = 'future-address-street'
export default {
  name: 'FutureAddressStreetField',

  extends: AddressStreetField,

  props: {
    field: {
      type: String,
      default: field,
    },
  },
}
</script>
