<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{ ...$attrs }"
    :class="[`field-${field}`]"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import AddressNumberField from 'chimera/all/components/form/fields/addressNumber/AddressNumberField'

export const field = 'future-address-number'
export default {
  name: 'FutureAddressNumberField',

  extends: AddressNumberField,

  props: {
    field: {
      type: String,
      default: field,
    },
  },
}
</script>
